<template>
    <v-row justify="center" align="center" class="ma-8">
        <v-col cols="12">
            <v-overlay>
                <AccessDeniedCard></AccessDeniedCard>
            </v-overlay>
        </v-col>
    </v-row>
</template>
<script>
import AccessDeniedCard from '@/components/AccessDeniedCard.vue';

export default {
    // props: ['visible'],
    components: {
        AccessDeniedCard,
    },
};
</script>
