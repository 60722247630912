<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="$route.params.accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">Account Dashboard</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Realm overview</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ displayName }}</h1>
                <p class="text-caption text-center">Realm overview</p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 px-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0"> -->
            <v-row justify="center" class="py-5 px-10" v-if="realm">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Authentication Realm</v-app-bar-title>
                            <v-spacer></v-spacer>
                            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'realm-settings', params: { accountId: this.$route.params.accountId } }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'cog']" :color="primaryColor" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Settings</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </v-app-bar>
                        <v-card-text>

                        <!-- TODO: OPEN QUESTION: show {{ displayName }} and {{ name }} (alias) again? it's already in the realmbar at the top of the page...  -->

                        <!-- <p class="text-overline mb-0 mt-5">Realm logo image</p> -->
                        <v-row no-gutters align="center" style="width: 300px;">
                            <RealmLogoImage :realmId="realm.id" :etag="realm.etag" :width="200" contain/>
                        </v-row>

                        <!-- TODO: load the count of registered users to show here; maybe show 10 most recently added users, or something else useful in a realm dashboard -->
                        <p class="text-overline mb-0 mt-5">Apps</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'realm-app-list', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">Registered apps</router-link>
                        </p>

                        <p class="text-overline mb-0 mt-5">Users</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'realm-user-list', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">Registered users</router-link>
                        </p>

                        <!-- TODO: show {{ brandprofileAlias }} ?  it's already in settings; do we need that in dashbaord?  or maybe show an alert if brandprofile is NOT set up, to prompt admin to set it up -->

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import RealmLogoImage from '@/components/RealmLogoImage.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';

export default {
    components: {
        RealmLogoImage,
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        serviceConfig: null,
        forbiddenError: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
        }),
        displayName() {
            return this.realm?.display_name ?? this.realm?.alias ?? 'Unknown realm';
        },
        name() {
            return this.realm?.alias ?? 'Unknown realm';
        },
        loginURL() {
            return this.realm?.info?.login_url_template ?? this.serviceConfig?.login_url_template ?? 'Undefined';
        },
        brandprofileAlias() {
            return this.realm?.info?.brandprofile_alias ?? ''; // '(Not connected)';
        },
        realmLogoImage() {
            return false;
        },
    },
    methods: {
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/dashboard.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadServiceConfig() {
            try {
                this.$store.commit('loading', { loadServiceConfig: true });
                const result = await this.$client.main().setting.search();
                console.log(`realm/dashboard.vue loadServiceConfig result: ${JSON.stringify(result)}`);
                if (result && Array.isArray(result.list)) {
                    const configMap = {};
                    result.list.forEach((item) => { configMap[item.name] = item.content; });
                    this.serviceConfig = configMap;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadServiceConfig failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load service settings', message });
            } finally {
                this.$store.commit('loading', { loadServiceConfig: false });
            }
        },
    },
    mounted() {
        this.loadRealm();
        this.loadServiceConfig();
    },
};
</script>
