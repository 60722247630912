<template>
    <v-card flat>
        <v-toolbar flat color="grey lighten-5">
            <v-toolbar-title>
                <font-awesome-icon :icon="['fas', 'globe']"></font-awesome-icon>
                <span class="ml-4">{{ realm.display_name }} ({{ realm.alias }})</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <span style="font-size: 0.75em;">
                <router-link :to="{ name: 'realm-dashboard', params: { accountId, realmId: realm.id } }">Overview</router-link>
                <span class="mx-4"></span>
                <router-link :to="{ name: 'realm-app-list', params: { accountId, realmId: realm.id } }">Apps</router-link>
                <span class="mx-4"></span>
                <router-link :to="{ name: 'realm-user-list', params: { accountId, realmId: realm.id } }">Users</router-link>
                <span class="mx-4"></span>
                <router-link :to="{ name: 'realm-search-client', params: { accountId, realmId: realm.id } }">Clients</router-link>
            </span>
        </v-toolbar>
    </v-card>
</template>
<script>
export default {
    props: ['accountId', 'realm'],
};
</script>
