<template>
    <v-card color="white">
        <v-toolbar dense flat color="red darken-2" dark>
            <v-toolbar-title>
            Access denied
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="black--text">
            <p class="mb-0">You do not have permission to access the requested resource.</p>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="black" @click="$router.history.go(-1)">
                <font-awesome-icon :icon="['fas', 'chevron-left']"></font-awesome-icon>
                <span class="ml-2">Go back</span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
};
</script>
